const appConfig = {

  env: "production",

  production: {

    host: "https://api.ticketandgo.com.br",

    socketUrl: "https://api.ticketandgo.com.br",

    api: "https://api.ticketandgo.com.br",

    //apiv2: 'https://api-v2-producao.jelastic.saveincloud.net',
    apiv2: 'https://api-v2-producao.ticketandgo.com.br',

    apiCheckout: 'https://checkout.ticketandgo.com.br',
    dashboardUrl: 'https://dashboard.ticketandgo.com.br',
  },

  development: {

    host: "https://api.ticketandgo.com.br",

    socketUrl: "https://api.ticketandgo.com.br",

    api: "https://api.ticketandgo.com.br",

    apiv2: 'https://node167603-api-v2.jelastic.saveincloud.net',

    apiCheckout: 'https://checkout-prod.ticketandgo.com.br',
    dashboardUrl: 'https://dashboard-prod.ticketandgo.com.br',
  },

};



export default appConfig;


