import { Box, Button, Typography, Checkbox, Skeleton } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import * as React from 'react';
import { useState } from 'react';
import setabaixo from 'assets/svgs/global/setabaixo.svg';
import TGButton from './TGButton';
export default function TGRadioGroup({
  title,
  textHelper,
  placeholder,
  value,
  options,
  onChange,
  styleProps,
  error,
  loading,
  showOptions,
  setShowOptions,
}) {
  const handleCheckboxChange = (optionValue) => {
    if (optionValue === 'all') {
      if (value.includes('all')) {
        onChange([]);
      } else {
        onChange(['all']);
      }
    } else {
      if (value.includes('all')) {
        onChange([optionValue]);
      } else {
        if (value.includes(optionValue)) {
          onChange(value.filter((item) => item !== optionValue));
        } else {
          onChange([...value, optionValue]);
        }
      }
    }
  };

  return (
    <Box
      width={'100%'}
      display={'flex'}
      gap={'4px'}
      flexDirection={'column'}
      {...styleProps}
    >
      <Typography color='#555555' fontSize={'12px'} lineHeight={'18px'}>
        {title}
      </Typography>
      {loading ? (
        <Skeleton height='45px' />
      ) : (
        <>
          <Box
            padding='12px 14px'
            display={'flex'}
            justifyContent={'space-between'}
            borderRadius={'8px'}
            bgcolor={'#fafafa'}
            border={'1px solid #EEEEEE'}
            sx={{ cursor: 'pointer' }}
            textTransform={'initial'}
            component={Button}
            onClick={() => setShowOptions(!showOptions)}
          >
            <Typography color='#999999' fontSize={'14px'}>
              {placeholder}
            </Typography>
            <img
              src={setabaixo}
              alt='arrowdownIcon'
              height='20px'
              width={'20px'}
            />
          </Box>
          {showOptions ? (
            <>
              <Box
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'space-between'}
                borderRadius={'8px'}
                bgcolor={'#fafafa'}
                border={'1px solid #EEEEEE'}
              >
                {options?.map((option, index) => (
                  <Box
                    key={index}
                    display={'flex'}
                    alignItems={'center'}
                    padding='12px 8px'
                    height='41px'
                    bgcolor={
                      value.includes(option.value) ? '#E6F5FB' : '#ffffff'
                    }
                  >
                    <Checkbox
                      sx={{
                        '&.MuiCheckbox-root': {
                          '& svg': {
                            color: '#EEEEEE',
                            height: '20px',
                            width: '20px',
                          },
                        },
                        '&.MuiCheckbox-root.Mui-checked': {
                          '& svg': {
                            color: '#00B2FF',
                            height: '20px',
                            width: '20px',
                          },
                        },
                      }}
                      checked={value.includes(option.value)}
                      onChange={() => handleCheckboxChange(option.value)}
                    />
                    <Typography fontSize={'14px'} color='#555555'>
                      {option.label}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </>
          ) : null}
        </>
      )}

      {textHelper && !error && (
        <Typography
          color='#999999'
          lineHeight={'15px'}
          fontSize={'10px'}
          fontWeight={'400'}
        >
          {textHelper}
        </Typography>
      )}
      {error && (
        <Typography
          color='red'
          lineHeight={'15px'}
          fontSize={'10px'}
          fontWeight={'400'}
        >
          {error}
        </Typography>
      )}
    </Box>
  );
}
