// MenuItemsEvent.js
import { credenciamento } from 'assets/svgs'
import { pixel } from 'assets/svgs'
import {
  cooproducao,
  resumo,
  formulario,
  paginaVenda,
  ofertas,
  cupom,
  listaMembros,
  gerenciarTurmas,
  usersGroup,
} from 'assets/svgs'

const MenuItemsEvent = [
  {
    text: 'Resumo',
    icon: resumo,
    to: 'event/resumo',
    roles: ['PRODUTOR-OPERACIONAL', 'PRODUTOR-ADMIN', 'PRODUTOR-FINANCEIRO'],
    adm: true,
  },
  {
    text: 'Página de vendas',
    icon: paginaVenda,
    to: 'event/paypage',
    roles: ['PRODUTOR-OPERACIONAL', 'PRODUTOR-ADMIN', 'PRODUTOR-FINANCEIRO'],
    adm: true,
  },
  {
    text: 'Ingressos',
    icon: ofertas,
    to: 'event/ofertas',
    roles: ['PRODUTOR-OPERACIONAL', 'PRODUTOR-ADMIN', 'PRODUTOR-FINANCEIRO'],
    adm: true,
  },
  {
    text: 'Cupom',
    icon: cupom,
    to: 'event/cupons',
    roles: ['PRODUTOR-OPERACIONAL', 'PRODUTOR-ADMIN', 'PRODUTOR-FINANCEIRO'],
    adm: true,
  },
  {
    text: 'Formulário',
    icon: formulario,
    to: 'event/membros',
    roles: ['PRODUTOR-OPERACIONAL', 'PRODUTOR-ADMIN', 'PRODUTOR-FINANCEIRO'],
    adm: true,
  },
  {
    text: 'Participantes',
    icon: usersGroup,
    to: 'event/turma',
    roles: ['PRODUTOR-OPERACIONAL', 'PRODUTOR-ADMIN', 'PRODUTOR-FINANCEIRO'],
    adm: true,
  },
  {
    text: 'Coprodução',
    icon: cooproducao,
    to: 'event/coproducoes',
    roles: ['PRODUTOR-OPERACIONAL', 'PRODUTOR-ADMIN', 'PRODUTOR-FINANCEIRO'],
    adm: true,
  },
  {
    text: 'Credenciamento',
    icon: credenciamento,
    to: 'event/coproducoes',
    roles: ['PRODUTOR-OPERACIONAL', 'PRODUTOR-ADMIN', 'PRODUTOR-FINANCEIRO'],
    adm: true,
  },
  {
    text: 'Pixel',
    icon: pixel,
    to: 'event/rastreamento',
    roles: ['PRODUTOR-OPERACIONAL', 'PRODUTOR-ADMIN', 'PRODUTOR-FINANCEIRO'],
    adm: true,
  },
]

export default MenuItemsEvent
