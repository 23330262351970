import React from 'react';
import { Box, Typography } from '@mui/material';
import bluearrow from 'assets/svgs/productadd/bluearrow.svg';
import { Link, useNavigate } from 'react-router-dom';

export default function TGBack({ href, rightComponent }) {
  const navigate = useNavigate();

  const handleClick = (e) => {
    if (!href) {
      e.preventDefault();
      navigate(-1);
    }
  };

  return (
    <Box
      alignItems={'center'}
      justifyContent={'space-between'}
      display={'flex'}
      flexDirection={'row'}
      gap={'12px'}
    >
      <Box
        sx={{ textDecoration: 'none' }}
        component={href ? Link : 'a'}
        to={href ? href : '#'}
        onClick={handleClick}
        alignItems={'center'}
        display={'flex'}
        flexDirection={'row'}
        gap={'12px'}
      >
        <img src={bluearrow} alt='dragimg' height={'10px'} width={'10px'} />
        <Typography
          color='#555555'
          fontSize={['12px', '12px', '16px']}
          lineHeight={'24px'}
        >
          Voltar
        </Typography>
      </Box>
      {rightComponent ? rightComponent : null}
    </Box>
  );
}
