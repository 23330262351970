import { Box, Input, Switch, TextField, Typography } from '@mui/material';
import React, { useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import dragimg from '../../assets/svgs/productadd/dragimg.svg';
import styled from '@emotion/styled';
import InputMask from 'react-input-mask';
import TGButton from './TGButton';
import appConfig from 'config';
import { fileIcon } from 'assets/svgs';

function InputText({
  name,
  placeholder,
  height = '100%',
  type = 'text',
  onChange,
  onBlur,
  value,
  required = false,
  disabled = false,
  border,
  warning,
  maskPlaceholder,
  mask,
  maskChar,
  id,
  fontSize,
  fontWeight,
  defaultValue,
  prefix,
  maxLength,
  ...otherProps
}) {
  const handleChange = (e) => {
    if (maxLength && e.target.value.length > maxLength) {
      e.target.value = e.target.value.slice(0, maxLength);
    }
    onChange(e);
  };

  return (
    <Box
      sx={{ position: 'relative', width: '100%' }}
      display='flex'
      justifyContent={'space-between'}
    >
      {prefix && (
        <Box
          sx={{
            position: 'absolute',
            left: '14px',
            top: '50%',
            transform: 'translateY(-50%)',
            color: '#999',
            fontSize: fontSize,
            fontWeight: fontWeight,
          }}
        >
          {prefix}
        </Box>
      )}
      <InputMask
        mask={mask}
        maskChar={maskChar}
        value={value}
        onChange={handleChange}
        onBlur={onBlur}
        disabled={disabled}
        maskPlaceholder={maskPlaceholder}
        defaultValue={defaultValue}
      >
        {() => (
          <input
            id={id}
            name={name}
            onChange={handleChange}
            defaultValue={defaultValue}
            onBlur={onBlur}
            value={value}
            type={type}
            placeholder={placeholder}
            disabled={disabled}
            required={required}
            {...otherProps}
            style={{
              fontSize: fontSize,
              padding: '14px',
              backgroundColor: '#ffffff',
              borderRadius: '8px',
              border: `1px solid ${warning ? '#ff5858' : '#EEEEEE'} `,
              width: '100%',
              height: height,
              fontWeight: fontWeight,
              paddingLeft: prefix
                ? 'calc(-1px + ' + prefix.length * 8 + 'px)'
                : '14px',
            }}
          />
        )}
      </InputMask>
      {maxLength && prefix && (
        <Typography
          variant='body-s'
          color='rgba(34, 34, 34, 0.56)'
          ml='auto'
          sx={{
            position: 'absolute',
            bottom: '-20px', // ajusta a posição conforme necessário
            right: '10px',
          }}
        >
          {value ? value.length : 0}/{maxLength}
        </Typography>
      )}
    </Box>
  );
}

const textAreaStyle = {
  color: 'blue',
  fontSize: '14px',
  padding: '14px',
  backgroundColor: '#ffffff',
  borderRadius: '8px',
  border: '1px solid #EEEEEE',
  width: '100%',
  resize: 'none',
};

function CustomTextArea({
  title,
  placeholder,
  height = '100%',
  resize = 'none',
  onChange,
  style = {},
  ...props
}) {
  const textAreaId = title
    ? `${title.replace(/\s+/g, '-').toLowerCase()}-textarea`
    : undefined;

  return (
    <div style={{ marginBottom: '10px' }}>
      {title && (
        <label
          htmlFor={textAreaId}
          style={{ marginBottom: '5px', display: 'block' }}
        >
          {title}
        </label>
      )}
      <textarea
        id={textAreaId}
        placeholder={placeholder}
        onChange={onChange}
        style={{ ...textAreaStyle, ...style, height, resize }}
        {...props}
      />
    </div>
  );
}

const FileUpload = ({ onFileUpload, onFileRemove, file, type }) => {
  const [isHovered, setIsHovered] = useState(false);

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    onDragEnter: () => setIsHovered(true),
    onDragLeave: () => setIsHovered(false),
    onDrop: () => setIsHovered(false),
    onDropAccepted: (files) => onFileUpload(files[0]),
    accept: 'image/*',
  });

  const removeFile = (event) => {
    onFileUpload(null);
    event.stopPropagation();
  };

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      border='1px dashed'
      borderColor={isHovered ? '#00B2FF' : '#EEEEEE'}
      borderRadius={'8px'}
      pt='27px'
      pb='27px'
      {...getRootProps()}
    >
      <input {...getInputProps()} />

      {typeof file === 'string' ? (
        <Box
          display={'flex'}
          gap='16px'
          justifyContent={'center'}
          flexDirection={['column', 'column', 'row']}
        >
          <Box mx='auto'>
            <img
              src={`${appConfig[appConfig.env].apiv2}/storage/${file}`}
              alt='Uploaded file'
              style={{
                width: '100px',
                height: '100px',
                borderRadius: '8px',
              }}
            />
          </Box>
          <Box alignItems={'center'} display={'flex'} gap='11px' my='auto'>
            <TGButton
              sx={{ px: '16px', py: '4px' }}
              fontSize={'14px'}
              color='#555'
              fontWeight={'500'}
              bgcolor='#fff'
              border='1px solid #EEE'
            >
              Alterar
            </TGButton>
            <Typography variant='body2' fontSize='12px' fontWeight={'500'}>
              ou
            </Typography>
            <TGButton
              sx={{ px: '16px', py: '4px' }}
              fontSize={'14px'}
              color='#555'
              fontWeight={'500'}
              bgcolor='#fff'
              border='1px solid #EEE'
              onClick={(event) => removeFile(event)}
            >
              Remover
            </TGButton>
          </Box>
        </Box>
      ) : acceptedFiles.length === 0 || file === null ? (
        <>
          <img
            src={type === 'image' ? dragimg : fileIcon}
            alt='dragimg'
            height={'32px'}
            width={'32px'}
          />
          <Typography
            color='#999999'
            fontSize='12px'
            fontWeight={'400'}
            mt={'15px'}
          >
            Arraste {type === 'image' ? 'uma imagem' : 'um arquivo'} aqui ou{' '}
            <span style={{ color: '#00B2FF', cursor: 'pointer' }}>
              clique para buscar
            </span>
          </Typography>
        </>
      ) : null}

      {file !== null &&
        acceptedFiles.map((file) => (
          <Box
            display={'flex'}
            gap='16px'
            justifyContent={'center'}
            flexDirection={['column', 'column', 'row']}
          >
            <Box mx='auto'>
              {type === 'image' ? (
                <img
                  key={file.path}
                  src={
                    file instanceof File
                      ? URL.createObjectURL(file)
                      : `${appConfig[appConfig.env].apiv2}/storage/${file}`
                  }
                  alt={file.path}
                  style={{
                    width: '100px',
                    height: '100px',
                    borderRadius: '8px',
                  }}
                />
              ) : (
                file.path
              )}
            </Box>
            <Box alignItems={'center'} display={'flex'} gap='11px' my='auto'>
              <TGButton
                sx={{ px: '16px', py: '4px' }}
                fontSize={'14px'}
                color='#555'
                fontWeight={'500'}
                bgcolor='#fff'
                border='1px solid #EEE'
              >
                Alterar
              </TGButton>
              <Typography variant='body2' fontSize='12px' fontWeight={'500'}>
                ou
              </Typography>
              <TGButton
                sx={{ px: '16px', py: '4px' }}
                fontSize={'14px'}
                color='#555'
                fontWeight={'500'}
                bgcolor='#fff'
                border='1px solid #EEE'
                onClick={(event) => removeFile(event)}
              >
                Remover
              </TGButton>
            </Box>
          </Box>
        ))}
    </Box>
  );
};

const FileUploadInline = ({ onFileUpload, onFileRemove, file }) => {
  const [isHovered, setIsHovered] = useState(false);

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    onDragEnter: () => setIsHovered(true),
    onDragLeave: () => setIsHovered(false),
    onDrop: () => setIsHovered(false),
    onDropAccepted: (files) => onFileUpload(files[0]),
    accept: 'image/*',
  });

  const removeFile = (event) => {
    onFileUpload(null);
    event.stopPropagation();
  };

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      border='1px dashed'
      borderColor={isHovered ? '#00B2FF' : '#EEEEEE'}
      borderRadius={'8px'}
      padding='16px'
      {...getRootProps()}
    >
      <input {...getInputProps()} />

      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '65px',
        }}
      >
        {(acceptedFiles?.length === 0 || file === null) && (
          <>
            <Box
              sx={{
                width: '64px',
                height: '64px',
                border: ' 1px dashed #EEE',
                borderRadius: '32px',
                background: '#FFF',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img src={dragimg} alt='dragimg' height={'32px'} width={'32px'} />
            </Box>

            <TGButton
              borderRadius={'8px'}
              sx={{
                width: 'max-content',
                padding: '4px 16px',
                height: '32px',
                minHeight: '32px',
              }}
            >
              Adicionar imagem
            </TGButton>
          </>
        )}
      </Box>

      {file !== null &&
        acceptedFiles.map((file) => (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: '65px',
            }}
          >
            <Box
              sx={{
                width: '64px',
                height: '64px',
                border: ' 1px dashed #EEE',
                borderRadius: '32px',
                background: '#FFF',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img
                key={file.path}
                src={
                  file instanceof File
                    ? URL.createObjectURL(file)
                    : `https://node167603-api-v2.jelastic.saveincloud.net/storage/${file}`
                }
                alt={file.path}
                style={{
                  width: '64px',
                  height: '64px',
                  borderRadius: '32px',
                  objectFit: 'contain',
                }}
              />
            </Box>
            <TGButton
              borderRadius={'8px'}
              sx={{
                width: 'max-content',
                padding: '4px 16px',
                height: '32px',
                minHeight: '32px',
              }}
              onClick={(event) => removeFile(event)}
            >
              Remover
            </TGButton>
          </Box>
        ))}
    </Box>
  );
};

const IOSSwitch = styled((props) => (
  <Switch
    focusVisibleClassName='.Mui-focusVisible'
    onChange={props.onChange}
    disableRipple
    {...props}
  />
))(({ theme }) => ({
  'width': 42,
  'height': 26,
  'padding': 0,
  '& .MuiSwitch-switchBase': {
    'padding': 0,
    'margin': 2,
    'transitionDuration': '300ms',
    '&.Mui-checked': {
      'transform': 'translateX(16px)',
      'color': '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#00B2FF' : '#00B2FF',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#00B2FF',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

export { FileUpload, InputText, IOSSwitch, CustomTextArea, FileUploadInline };
