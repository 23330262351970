import { Box, Button, Typography, Skeleton } from '@mui/material';
import { useState } from 'react';
import { IOSSwitch } from './Inputs';

export default function TGBoxSwitch({
  icon,
  placeholder,
  helpText = '',
  isSwitched = false,
  border = '1px solid #EEEEEE',
  padding = '12px 16px 12px 16px',
  placeholderColor = '#999999',
  bgcolor = '#fafafa',
  onSwitchChange = () => {},
  canSwitch = true,
  label,
  disabled,
  loading,
}) {
  const [switched, setSwitched] = useState(isSwitched);

  const handleChange = () => {
    const newSwitchState = !switched;
    if (canSwitch) {
      setSwitched(newSwitchState);
    }
    onSwitchChange(newSwitchState);
  };

  return (
    <>
      <Box width={'100%'} display={'flex'} gap={'4px'} flexDirection={'column'}>
        {label ? (
          <Typography color='#555555' fontSize={'12px'} lineHeight={'18px'}>
            {label}
          </Typography>
        ) : null}
        {loading ? (
          <Skeleton height='48px' />
        ) : (
          <Box
            disabled={disabled}
            component={Button}
            onClick={handleChange}
            borderRadius={'8px'}
            border={border}
            bgcolor={bgcolor}
            width={'100%'}
            display={'flex'}
            alignContent={'center'}
            alignItems={'center'}
            justifyContent={'space-between'}
            padding={padding}
            gap='12px'
          >
            <Box display={'flex'} gap={'12px'} alignItems={'center'}>
              {icon && (
                <img src={icon} alt='icon' height={'20px'} width={'20px'} />
              )}
              <Typography
                textAlign={'start'}
                color={placeholderColor}
                fontSize={'14px'}
                fontWeight={'400'}
                textTransform={'initial'}
              >
                {placeholder}
              </Typography>
            </Box>
            <IOSSwitch
              onChange={handleChange}
              checked={switched}
              disabled={disabled}
            />
          </Box>
        )}
        {helpText && (
          <Typography
            color='#999999'
            lineHeight={'15px'}
            fontSize={'10px'}
            fontWeight={'400'}
          >
            {helpText}
          </Typography>
        )}
      </Box>
    </>
  );
}
