import React, { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import Select from './Select';
import * as listsActions from '../../actions/listsActions';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Typography } from '@mui/material';
import './EventSelect.css';
import { TGSelectAuto } from 'TGComponents/global';
import { useFormikContext } from 'formik';
const EventSelectAuto = ({
  label = 'Tipo de evento',
  name,
  setUuidEvento,
  errors = {},
  boComponenteNovo = false, //Na homologação trocar o valor para true
  boUsuarioVariasProdutoras = 0,
  ...props
}) => {
  const { values, setFieldValue } = useFormikContext();

  const items = useSelector((state) => state.lists.events || []);
  const user = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const getItems = () =>
    dispatch(
      listsActions.getLists(`/usuarios/${user.uuid}/eventos`, {
        list: 'events',
        arg: [
          '?boUsuarioVariasProdutoras=' + (user.boUsuarioVariasProdutoras ? 1 : 0),
        ],
      })
    );

  useEffect(() => {
    getItems();
  }, []);
  const modifiedEvents = items.map(function (item) {
    return { label: item.nome, value: item.uuid };
  });

  const findLabelByUuid = (uuid) => {
    const item = modifiedEvents.find((option) => option.value === uuid);
    return item ? item : null;
  };

  return (
    <>
      <Typography
        fontSize='12px'
        color='#555'
        mb='4px'
        htmlFor={props.id || props.name}
      >
        Evento/Produto
      </Typography>

      <TGSelectAuto
        multiple={false}
        placeholder='Selecione os produtos'
        values={modifiedEvents}
        selectedValues={findLabelByUuid(values[name])}
        onChange={(e) => {
          const selectedLabel = e.target.value;
          setFieldValue(name, selectedLabel);
          setUuidEvento(selectedLabel)
        }}
      />
    </>
  );
};

export default EventSelectAuto;
