import React, { useState, forwardRef } from 'react';
import { Formik, Form, useFormikContext } from 'formik';
import SpinnerButton from './SpinnerButton';
import qs from 'qs';

import Input from './Input';
import Select from './Select';
import TicketType from './TicketTypeSelect';
import DateInput from './Date';
import Currency from './Currency';
import TextArea from './TextArea';
import ProducerSelect from './ProducerSelect';
import PerfilSelectId from './PerfilSelectId';
import EventSelect from './EventSelect';
import TicketSelect from './TicketSelect';
import BilheteCheckoutSelect from './BilheteCheckoutSelect';
import YesNoSelect from './YesNoSelect';
import OptionSelect from './OptionSelect';
import ProvedorSelect from './ProvedorSelect';
import TGButton from 'TGComponents/global/TGButton';
import TGTextField from 'TGComponents/global/TGTextField';
import { TGSelectAuto } from 'TGComponents/global';
import EventSelectAuto from './EventSelectAuto';

let initialValues = {
  date: '',
  producer: '',
  produtora: '',
  Event: '',
  event: '',
  evento: '',
  uuid_evento: '',
  ticket: '',
  options: '',
  code: '',
  email: '',
  nome: '',
  inicio: '',
  fim: '',
  inicioReport: '',
  fimReport: '',
  cpf: '',
  cliente: '',
  ingresso: '',
  id_situacao_pagamento: '',
  credenciados: '',
  operador: '',
  codigo: '',
  Ticket: '',
  idProvedor: '',
};

const Search = forwardRef((props, ref, loading2) => {
  const [formValues, setformValues] = useState(initialValues);
  const getFormData = (values) => {
    //console.log("getFormData::", values);
  };
  //console.log("formValues::", formValues);

  const [uuidEvento, setUuidEvento] = useState(null);
  const [idProvedor, setIdProvedor] = useState(null);

  const {
    fields,
    handleSearch,
    overrides = {},
    handleClear = false,
    loading = false,
  } = props;
  const renderField = (field) => {
    switch (field.role) {
      case 'date': {
        return (
          <DateInput label={field.label} name={field.name} id={field.name} />
        );
      }
      case 'cpf': {
        return (
          <TGTextField
            mask='999.999.999-99'
            label={field.label}
            type='text'
            name={field.name}
            id={field.name}
          />
        );
      }

      case 'producer': {
        return (
          <ProducerSelect
            label={field.label}
            name={field.name}
            id={field.name}
          />
        );
      }
      case 'perfilId': {
        return (
          <PerfilSelectId
            label={field.label}
            name={field.name}
            id={field.name}
          />
        );
      }
      case 'event': {
        return (
          <EventSelect
            label={field.label}
            name={field.name}
            id={field.name}
            onChange={(e, newValue) => {
              setUuidEvento(e.target.value);
              //setUuidEvento(newValue?.id)
            }}
          />
        );
      }
      case 'auto': {
        return (
          <EventSelectAuto
            label={field.label} 
            name={field.name}
            id={field.name}
            setUuidEvento={setUuidEvento}
            onChange={(e, newValue) => {
              setUuidEvento(e.target.value);
              //setUuidEvento(newValue?.id)
            }}
          />
        );
      }
      case 'ticket': {
        return (
          <TicketSelect
            label={field.label}
            name={field.name}
            id={field.name}
            uuidEvento={uuidEvento}
          />
        );
      }
      case 'ticketcheckout': {
        return (
          <BilheteCheckoutSelect
            label={field.label}
            name={field.name}
            id={field.name}
            uuidEvento={uuidEvento}
          />
        );
      }
      case 'yesno': {
        return (
          <YesNoSelect label={field.label} name={field.name} id={field.name} />
        );
      }
      case 'options': {
        return (
          <OptionSelect
            label={field.label}
            name={field.name}
            id={field.name}
            options={field.options}
            defaultReport={field.defaultReport}
            onChange={field.onChange}
          />
        );
      }
      case 'provedor': {
        return (
          <ProvedorSelect
            label={field.label}
            name={field.name}
            id={field.name}
            onChange={(e, newValue) => {
              setIdProvedor(newValue?.id);
            }}
          />
        );
      }
      default: {
        return (
          <TGTextField
            placeholder={field.label}
            label={field.label}
            type='text'
            name={field.name}
            id={field.name}
          />
        );
      }
    }
  };

  return (
    <div className='col-12'>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={(values) => {
          Object.keys(values).forEach((key) => {
            if (values[key].trim() === '') {
              delete values[key];
            }
          });

          Object.keys(overrides).forEach((key) => {
            if (overrides[key].trim() === '') {
              delete values[key];
            } else {
              values[key] = overrides[key];
            }
          });

          const arg =
            qs.stringify(values) +
            (uuidEvento ? '&evento=' + uuidEvento : '') +
            (idProvedor ? '&idProvedor=' + idProvedor : '');
          handleSearch('?' + arg);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          resetForm,
          /* and other goodies */
        }) => {
          setformValues(values);
          getFormData(values);
          const resetFormTemp = () => {
            initialValues = {
              date: '',
              producer: '',
              produtora: '',
              Event: '',
              event: '',
              evento: '',
              uuid_evento: '',
              ticket: '',
              options: '',
              code: '',
              email: '',
              nome: '',
              inicio: '',
              fim: '',
              inicioReport: ``,
              fimReport: ``,
              cpf: '',
              cliente: '',
              ingresso: '',
              id_situacao_pagamento: '',
              credenciados: '',
              operador: '',
              codigo: '',
              Ticket: '',
              idProvedor: '',
            };
            resetForm();
          };
          return (
            <Form>
              <div className='row my-3'>
                {fields.map(
                  (field, idx) =>
                    field.name && (
                      <div
                        className='col-sm-2 mb-3'
                        key={idx}
                        style={{ minWidth: '280px' }}
                      >
                        {renderField(field)}
                      </div>
                    )
                )}
                <div
                  className='col-sm-2 mb-3'
                  style={{ minWidth: '280px', marginTop: '27px' }}
                >
                  <TGButton
                    variant='outlined'
                    type='reset'
                    className='btn btn-azul-pequeno btn-lateral me-2'
                    value='Reset'
                    disabled={loading || loading2}
                    onClick={() => {
                      resetFormTemp();

                      fields.forEach((field) => {
                        setFieldValue(field.name, '');
                        setFieldValue(`${field.name}-flat`, '');
                      });

                      if (handleClear !== false) {
                        handleClear();
                      } else {
                        handleSearch();
                      }
                    }}
                  >
                    LIMPAR
                  </TGButton>
                  <TGButton
                    type='submit'
                    className='btn btn-verde-pequeno btn-lateral'
                    ref={ref}
                    disabled={loading || loading2}
                  >
                    {loading || loading2 ? (
                      <span
                        class='spinner-border spinner-border-sm'
                        role='status'
                        aria-hidden='true'
                      ></span>
                    ) : (
                      'PESQUISAR'
                    )}
                  </TGButton>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
});

export default Search;
