import React from 'react'
import { Button, CircularProgress } from '@mui/material'
import { styled } from '@mui/system'

const TGButtonBase = styled(Button)(
  ({
    theme,
    fontSize,
    border,
    fontWeight,
    bgcolor,
    textColor,
    padding,
    maxWidth,
    minHeight,
    width,
    height,
    borderRadius,
    bgcolorHover,
    colorHover,
  }) => ({
    'border': border,
    'backgroundColor': bgcolor || '#FFF',
    'color': textColor || '#FFF',
    'padding': padding || '11px 28px',
    'maxWidth': maxWidth,
    'minHeight': minHeight,
    'width': width,
    'height': height,
    'borderRadius': borderRadius,
    'whiteSpace': 'nowrap',
    'fontStyle': 'normal',
    'textTransform': 'initial',
    'fontWeight': fontWeight,
    'fontSize': fontSize,
    'lineHeight': '24px',
    'position': 'relative',
    '&:hover': {
      background: bgcolorHover,
      color: colorHover,
    },
  })
)

const TGButton = ({
  variant = 'contained',
  color = variant === 'outlined' ? '#00B2F' : '',
  padding,
  children,
  borderRadius = '8px',
  bgcolor = variant === 'outlined' ? 'transparent' : '#00b2FF',
  border,
  fontWeight,
  colorHover = variant === 'outlined' ? '#00b2FF' : '#fff',
  width,
  height,
  fontSize,
  bgcolorHover = variant === 'outlined' ? '' : '#00b2FF',
  leftIcon,
  rightIcon,
  minHeight = '45px',
  sx,
  loading = false,
  submitting = false,
  ...props
}) => (
  <TGButtonBase
    fontSize={fontSize}
    border={border}
    bgcolor={bgcolor}
    bgcolorHover={bgcolorHover}
    colorHover={colorHover}
    textColor={color}
    padding={padding}
    minHeight={minHeight}
    width={width}
    height={height}
    borderRadius={borderRadius}
    fontWeight={fontWeight}
    variant={variant}
    disableElevation
    sx={sx}
    {...props}
  >
    {submitting ? (
      <CircularProgress size={24} color='inherit' width='100%' />
    ) : loading ? (
      <div class='dot-flashing'></div>
    ) : (
      <>
        {leftIcon ? (
          <img
            src={leftIcon}
            alt='moreIcon'
            height='18px'
            width='18px'
            style={{ marginRight: '5px' }}
          />
        ) : null}
        {children}
        {rightIcon ? (
          <img
            src={rightIcon}
            alt='moreIcon'
            height='18px'
            width='18px'
            style={{ marginLeft: '5px' }}
          />
        ) : null}
      </>
    )}
  </TGButtonBase>
)

export default TGButton
