import { createContext, useState } from 'react'
import TGAlert from 'TGComponents/global/TGAlert'

export const AlertsContext = createContext()

const AlertsProvider = ({ children }) => {
  const [alerts, setAlerts] = useState([])

  // Adiciona um alerta
  const addAlert = (alert) => {
    const id =
      Math.random().toString(36).slice(2, 9) + new Date().getTime().toString(36)
    setAlerts((prev) => [{ ...alert, id: id }, ...prev])
    return id
  }

  // Remove alerta
  const dismissAlert = (id) => {
    setAlerts((prev) => prev.filter((alert) => alert.id !== id))
  }

  return (
    <AlertsContext.Provider value={{ alerts, addAlert, dismissAlert }}>
      {alerts.map((alert) => (
        <TGAlert
          key={alert.id}
          message={alert.message}
          type={alert.type}
          open={alert.open}
          handleClose={() => dismissAlert(alert.id)}
          anchorOrigin={alert.anchorOrigin}
        />
      ))}
      {children}
    </AlertsContext.Provider>
  )
}

export default AlertsProvider
