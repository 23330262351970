import { Box, Typography, Skeleton } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import * as React from 'react';
import warningIcon from 'assets/svgs/global/warningIcon.svg';
import ArrowDown from 'assets/svgs/common/arrowDown.svg';
import TGIcon from './TGIcon';
import { ArrowDownIcon } from 'assets/svgs';

export default function TGSelectInput({
  title,
  textHelper,
  defaultValue,
  value,
  values,
  setValue,
  buttonStyle,
  placeholder,
  onChange,
  warning,
  errorMessage,
  loading,
  disabled,
  onBlur,
  name,
  height = '48px',
  inputBgColor,
  labelFontWeight = '500',
  variant,
}) {
  return (
    <Box width={'100%'} display={'flex'} gap={'4px'} flexDirection={'column'}>
      {title && (
        <Typography
          fontWeight={labelFontWeight}
          color='#555555'
          fontSize={'12px'}
          lineHeight={'18px'}
        >
          {title}
        </Typography>
      )}

      <Box position='relative'>
        {loading ? (
          <Skeleton height='45px' />
        ) : (
          <SelectInput
            bgColor={inputBgColor}
            defaultValue={defaultValue}
            values={values}
            value={value}
            setValue={setValue}
            buttonStyle={buttonStyle}
            placeholder={placeholder}
            onChange={onChange}
            warning={warning}
            disabled={disabled}
            onBlur={onBlur}
            name={name}
            sx={{ height }}
            variant={variant}
          />
        )}

        {warning ? (
          <img
            src={warningIcon}
            height='16px'
            width={'16px'}
            alt='warningIconTextArea'
            style={{ position: 'absolute', top: '13px', right: '10px' }}
          />
        ) : null}
      </Box>
      {textHelper && (
        <Typography
          color='#999999'
          lineHeight={'15px'}
          fontSize={'10px'}
          fontWeight={'400'}
        >
          {textHelper}
        </Typography>
      )}
      {errorMessage && (
        <Typography color='#FF5858' lineHeight={'15px'} fontSize={'10px'}>
          {errorMessage}
        </Typography>
      )}
    </Box>
  );
}

export function SelectInput({
  values,
  value,
  onChange,
  onBlur,
  name,
  defaultValue,
  placeholder = '',
  warning,
  buttonStyle = {
    height: '100%',
    padding: '14px',
    fontSize: '14px',
    width: '100%',
    color: '#555555',
  },
  disabled = false,
  bgColor = '#F7F9FA',
  variant,
}) {
  const [open, setOpen] = React.useState(false);
  const MenuProps = {
    PaperProps: {
      style: {
        'border': '1px solid #EEEEEE',
        'borderRadius': '8px',
        'paddingTop': '0px',
        'paddingBottom': '0px',
        'boxShadow': 'none',
        'marginTop': '5px',

        '&& .MuiList-root	': {
          padding: '0px !important',
        },
      },
    },
  };

  const MuiSelectStyle = {
    borderRadius: '8px !important',
    bgcolor: '#faf7f7',
    width: buttonStyle.width,
    height: buttonStyle.height,
    display: 'flex',
    justifyContent: 'space-between',
    padding: buttonStyle.padding,
    color: buttonStyle.color,
  };

  const wrapperModifiers = {
    secondary: {
      'bgcolor': '#fafafa',
      '&& .MuiSelect-filled	': {
        ...MuiSelectStyle,
        color: '#2222228F',
      },
    },
  };

  return (
    <Select
      defaultValue={defaultValue}
      IconComponent={() => (
        <Box
          component='img'
          src={ArrowDownIcon}
          alt='seta para baixo'
          sx={{
            marginRight: '12px',
            transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
            transition: 'transform 0.3s ease',
          }}
        />
      )}
      variant='filled'
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      sx={{
        '&& .MuiOutlinedInput-notchedOutline	': {
          border: `1px solid ${warning ? '#ff5858' : '#EEEEEE'} `,
        },
        'bgcolor': bgColor,

        '&& .MuiSelect-filled	': {
          ...MuiSelectStyle,
        },
        'fontSize': buttonStyle.fontSize,
        'minWidth': '66px',
        'width': '100%',
        'borderRadius': '8px !important',

        ...(variant && wrapperModifiers[variant]),
      }}
      displayEmpty
      //value={value ? value : placeholder}
      value={
        value !== ''
          ? values.find((option) => option.value === value)?.label ||
            placeholder
          : placeholder
      }
      onChange={onChange}
      onBlur={onBlur}
      name={name}
      input={<OutlinedInput />}
      MenuProps={MenuProps}
      disabled={disabled}
      renderValue={(selected) => {
        if (selected === '') {
          return null;
        }
        return selected;
      }}
    >
      {values.map((option, index) => (
        <MenuItem
          key={index}
          value={option.value ? option.value : value}
          sx={{ height: '45px' }}
        >
          {typeof option === 'object' ? option.label : value}
        </MenuItem>
      ))}
    </Select>
  );
}
