import axios from 'axios'
import appConfig from 'config'
import { useEffect, useState } from 'react'
import { store } from 'store'

const baseUrl = ' https://api-v2.jelastic.saveincloud.net/'
const baseUrlOld = `${appConfig[appConfig.env].api}`

const instance = axios.create({
  baseURL: baseUrl,
  headers: {
    'Content-Type': 'application/json',
  },
})

const getData = async (url, customErrorMessage, customHeaders = {}) => {
  try {
    const headers = {
      Authorization: `Bearer ${store.getState().user.access_token}`,
      uuidUsuario: store.getState().user.uuid,
      ...customHeaders,
    }
    const response = await instance.get(url, { headers })
    return response.data
  } catch (error) {
    handleFetchError(error, customErrorMessage)
    throw error
  }
}

const postAPIOld = async (
  url,
  data,
  customErrorMessage,
  customHeaders = {}
) => {
  try {
    const headers = {
      Authorization: `Bearer ${store.getState().user.access_token},`,
      ...customHeaders,
    }
    const response = await instance.post(`${baseUrlOld}/${url}`, data, {
      headers,
    })
    return response.data
  } catch (error) {
    handleFetchError(error, customErrorMessage)
    throw error
  }
}

const postData = async (url, data, customHeaders = {}, customErrorMessage) => {
  try {
    const headers = {
      Authorization: `Bearer ${store.getState().user.access_token}`,
      uuidUsuario: store.getState().user.uuid,
      ...(store.getState().user.roles[0]?.uuid_produtora && {
        uuidProdutora: store.getState().user.roles[0].uuid_produtora,
      }),

      ...customHeaders,
    }
    const response = await instance.post(url, data, { headers })
    return response.data
  } catch (error) {
    handleFetchError(error, customErrorMessage)
    throw error
  }
}

const handleFetchError = (error, customErrorMessage) => {
  if (error.response) {
    const errorMessage = `${error.response.data.message}`
    return errorMessage
  } else if (error.request) {
    const errorMessage = `${customErrorMessage}: Falha na resposta do servidor`
    return errorMessage
  } else {
    const errorMessage = `${customErrorMessage}: ${error.message}`
    return errorMessage
  }
}

// custom hook para get
const useFetchData = (url, customHeaders = {}) => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const headers = {
          Authorization: `Bearer ${store.getState().user.access_token}`,
          uuidUsuario: store.getState().user.uuid,
          ...customHeaders,
        }
        const response = await instance.get(url, { headers })
        setData(response.data.data)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        // Tratar erro, como exibir mensagem para o usuário
      }
    }

    fetchData()
  }, [])

  return { data, loading }
}

export { getData, postData, postAPIOld, handleFetchError, useFetchData }
