// Aqui contem todas as tipografias, baseado no figma
// Exemplos para utilizar a nova palheta de cor <Typography variant='display-l' bgcolor={'primary.10'}/>
// "l" para larger, "m" para medium e "s" para small
const typography = {
  'fontFamily': 'Poppins, sans-serif',
  'fontWeightRegular': 400,
  'fontWeightMedium': 500,
  'fontWeightBold': 600,
  //Variantes novas
  //displays
  'display-l': {
    fontSize: '56px',
    fontWeight: 700,
    color: '#222222',
  },
  'display-m': {
    fontSize: '40px',
    fontWeight: 600,
    color: '#222222',
  },
  'display-s': {
    fontSize: '32px',
    fontWeight: 600,
    color: '#222222',
  },
  //Headline
  'headline': {
    fontSize: '24px',
    fontWeight: 600,
    color: '#222222',
  },
  //titles
  'title-l': {
    fontSize: '18px',
    fontWeight: 600,
    color: '#222222',
  },
  'title-m': {
    fontSize: '16px',
    fontWeight: 600,
    color: '#222222',
  },
  'title-s': {
    fontSize: '14px',
    fontWeight: 600,
    color: '#222222',
  },
  //labels
  'label-l': {
    fontSize: '16px',
    fontWeight: 500,
    color: '#222222',
  },
  'label-m': {
    fontSize: '14px',
    fontWeight: 500,
    color: '#222222',
  },
  'label-s': {
    fontSize: '12px',
    fontWeight: 500,
    color: '#222222',
  },
  //bodies
  'body-l': {
    fontSize: '14px',
    fontWeight: 400,
    color: '#222222',
  },
  'body-m': {
    fontSize: '12px',
    fontWeight: 400,
    color: '#222222',
  },
  'body-s': {
    fontSize: '10px',
    fontWeight: 400,
    color: '#222222',
  },
  //Variantes antigas
  'title': {
    fontFamily: 'Poppins, sans-serif',
    fontSize: '1rem',
    fontWeight: 600,
    color: '#222',
  },
  'subtitle': {
    fontFamily: 'Poppins, sans-serif',
    fontSize: '0.75rem',
    fontWeight: 600,
    color: '#222',
  },
  'body2': {
    color: '#555',
  },
};

export default typography;
