import { useField, useFormikContext } from 'formik'
import { Typography } from '@mui/material'

import flatfy from '../../utils/flatfy'

const Select = ({
  label,
  classe,
  errors = {},
  onChange = () => void 0,
  ...props
}) => {
  const [field, meta] = useField(props)

  const { handleChange } = useFormikContext()

  const hasErrors = flatfy(errors, props.name) || false

  return (
    <>
      {' '}
      {label ? (
        <Typography
          fontSize='12px'
          color='#555'
          mb='4px'
          htmlFor={props.id || props.name}
        >
          {label}
        </Typography>
      ) : (
        ''
      )}
      <select
        style={{ height: '49px' }}
        className={`form-select ${hasErrors ? 'is-invalid' : ''} ${
          classe == 'normal' ? 'form-select-normal' : ''
        }`}
        {...field}
        {...props}
        onChange={(e) => {
          handleChange(e)
          onChange(e)
        }}
      />
      <div className='invalid-feedback'>{(hasErrors || []).join(' ')}</div>
    </>
  )
}

export default Select
