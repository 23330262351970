import { Link } from "react-router-dom"
const NotAllowed = () => {

    return (
        <div className="row">
            <div className="col mx-auto">
                <div className="card">
                    <div className="card-body d-flex flex-column align-items-center">
                        <h1 className="text-center display-1">403</h1>
                        <h2 className="text-center">Acesso negado</h2>
                        <p className="lead text-center">
                            Você tentou acessar uma área protegida. Caso o acesso seja necessário entre em contato 
                            com o admistrador resposável pela sua conta clique no botão abaixo para voltar
                            ao início.
                        </p>

                        <Link to="/admin" className="btn btn-primary">Voltar ao início</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NotAllowed