import { createSelector } from 'reselect';

const menus = (state, permission) => ({ menus: state.user.menus, permission });

const getPermission = (menus) => {
  /*return menus.flatMap(m => {
        if(m.children.length > 0){
            return [m.frontend_route, getPermission(m.children)]

        }
        return m.frontend_route
    })*/
  return menus.reduce((acc, m) => {
    if (m.children.length > 0) {
      return acc.concat(m.frontend_route).concat(getPermission(m.children));
    }
    return acc.concat(m.frontend_route);
  }, []);
};

const permissionSelector = createSelector(menus, ({ menus, permission }) => ({
  permissions: getPermission(menus),
  permission,
}));

//rotas do admin
const wl = [
  '/admin',
  'menu',
  'menu/add',
  'menu/index',
  'home/index',
  'user/add',
  'user/edit',
  'perfil/index',
  //pagina de produto antiga
  'produtoold/add',
  'produtoold/report',
  'produtoold/index',
  'produtoold/edit',
  //pagina de produto nova
  'produto/rastreamento',
  'produto/add',
  'produto/teste',
  'produto/index',
  'produto/edit',
  'produto/pricing',
  'produto/coproducoes',
  'produto/resumo',
  'produto/turma',
  'produto/ofertas',
  'produto/cupons',
  'produto/membros',
  'product/add',
  'product/index',
  'product/edit',
  'afiliados/resumo',
  'afiliados/afiliacoes',
  'afiliados/add',
  'afiliados/index',
  'afiliados/edit',
  'event/add',
  'event/registered',
  'event/calendar',
  'event/edit',
  'event/collaborator',
  'event/coupon',
  'event/couponadd',
  'event/couponedit',
  'layoutemail/confirm',
  'producer/add',
  'producer/new',
  'producer/index',
  'producer/edit',
  'producer/rate',
  'rate/index',
  'rate/edit',
  'rate/new',
  'backoffice/sales',
  'backoffice/withdraw/producer',
  'backoffice/reversal',
  'backoffice/analyze',
  'dashboard/index',

  'digibank/index',
  'digibank/pending',
  'digibank/calendar',
  'digibank/done',
  'digibank/saque',
  'digibank/history',
  'digibank/machine',
  'financial/wallet',
  'financial/sales',
  'financeiro/vendas',
  'financial/refunds',
  'financial/summary',
  'financial/modal/withdraw',
  'financial/history',
  'financial/reimbursement',
  'integrations/configuration',
  'integrations/automation',
  'report/sales',
  'report/detailrecovered',
  'report/recoveredsales',
  'report/detail',
  'report/accreditation',
  'report/dashboard',
  'report/custom',
  'report/customtour',
  'report/customglobal',
  'operator/index',
  'operator/add',
  'operator/import',
  'operator/edit',
  'databank/add',
  'databank/index',
  'databank/edit',
  'databank/rates',
  'analytics/index',
  'evo/index',
  'attendee/index',
  'attendee/add',
  'attendee/edit',
  'attendee/import',
  'attendee/email',
  'mailing/index',
  'mailing/add',
  'mailing/edit',
  'mailing/copy',
  'smtp/index',
  'smtp/add',
  'smtp/edit',
  'complimentary/index',
  'complimentary/add',
  'complimentary/edit',
  'course/index',
  'course/add',
  'course/edit',
  'course/sync',
  'faq/index',
  'faq/add',
  'faq/edit',
  'term/index',
  'policy/index',
  'user/index',
  'user/useradd',
  'indications/index',
  'book/indications',
  'vendas/checkout',
  'machine/settings',
  'relatorios/index',
  'relatorio/enotas',
  'relatorio/vendas/abandonadas',
  'relatorio/recusa/cartao',
];

const hasPermissionSelector = createSelector(
  permissionSelector,
  ({ permissions, permission }) =>
    permissions.some((p) => p === permission) || wl.includes(permission)
);

export { permissionSelector, hasPermissionSelector };
