import React, { useEffect, useState } from 'react'
import { Typography, Box, useMediaQuery } from '@mui/material'
import TGSimpleSlider from './TGSimpleSlider'

const TGTabs = ({
  openTab = 0,
  setOpenTab,
  tabContents,
  padding,
  mobileIcon,
  marginTop = '28px',
  px,
  noMobileStyle,
  onTabIndexChange,
}) => {
  const [tabIndex, setTabIndex] = useState(0)
  const isMobile = useMediaQuery('(max-width:600px)')

  useEffect(() => {
    setTabIndex(openTab)
  }, [openTab])

  const handleTabChange = (newTabIndex) => {
    setTabIndex(newTabIndex)
    if (setOpenTab) {
      setOpenTab(newTabIndex)
    }
    if (typeof onTabIndexChange === 'function') {
      onTabIndexChange(newTabIndex)
    }
  }

  const borderColors = ['#00B2FF', '#FF9D60', '#8960FF']

  const getTabStyle = (index) => {
    const isSelected = tabIndex === index
    const borderColor = isMobile && !noMobileStyle ? '#FAFAFA' : 'transparent'
    const backgroundColor = isSelected ? borderColor : 'transparent'

    return {
      display: 'inline-block',
      paddingLeft: 0,
      marginRight: '16px',
      cursor: 'pointer',
      minWidth: isMobile && !noMobileStyle ? '300px' : 'auto',
      paddingBlock: isMobile && !noMobileStyle ? '16px' : '0',
      textIndent: isMobile && !noMobileStyle ? '16px' : '',
      borderLeft:
        isMobile && !noMobileStyle
          ? `16px solid ${borderColors[index]}`
          : 'none',
      borderRadius: '12px',
      marginBottom: isMobile && !noMobileStyle ? '14px' : '',
      backgroundColor: isMobile && !noMobileStyle ? '#FAFAFA' : 'transparent',
    }
  }

  const tabLabelStyle = (isSelected) => ({
    fontSize: '18px',
    whiteSpace: 'nowrap',
    textTransform: 'capitalize',
    color: isSelected ? '#000' : '#999',
    fontWeight: isSelected ? '600' : 'semibold',
  })

  const indicatorStyle = {
    height: '2px',
    borderRadius: '4px',
    maxWidth: '80%',
    backgroundColor: isMobile && !noMobileStyle ? 'transparent' : '#00B2FF',
    marginTop: isMobile && !noMobileStyle ? '0' : '8px',
  }
  const indicatorStyleBack = {
    width: '95%',
    height: '2px',
    borderRadius: '4px',
    backgroundColor: 'rgba(34, 34, 34, 0.08)',
    marginTop: isMobile && !noMobileStyle ? '0' : '8px',
  }

  return (
    <div>
      <Box
        px={px}
        style={{ marginTop: marginTop }}
        position='relative'
        padding={padding}
      >
        <TGSimpleSlider>
          {tabContents.map(({ mobileIcon, key }, index) => (
            <div
              key={index}
              style={getTabStyle(index)}
              onClick={() => handleTabChange(index)}
            >
              <Box display='flex'>
                {isMobile && !noMobileStyle && mobileIcon && (
                  <img
                    src={mobileIcon}
                    alt={key}
                    style={{ marginLeft: '16px' }}
                  />
                )}
                <Typography sx={tabLabelStyle(tabIndex === index)}>
                  {key}
                </Typography>
              </Box>
              {tabIndex === index && <Box style={indicatorStyle} />}
            </div>
          ))}
        </TGSimpleSlider>
        <Box position={'absolute'} top='27px' style={indicatorStyleBack} />
      </Box>

      {tabContents.map(({ content }, index) => (
        <div
          key={index}
          style={{
            display: tabIndex === index ? 'block' : 'none',
          }}
        >
          {content}
        </div>
      ))}
    </div>
  )
}

export default TGTabs
