import { Box, Typography } from '@mui/material'
import { FileUpload } from 'TGComponents/global/Inputs'

export default function TGUploadSingle({
  title,
  textHelper,
  onFileUpload,
  file,
  type = 'image',
}) {
  const handleFileUpload = (file) => {
    onFileUpload(file)
  }

  return (
    <Box width={'100%'} display={'flex'} gap={'4px'} flexDirection={'column'}>
      <Typography color='#555555' fontSize={'12px'} lineHeight={'18px'}>
        {title}
      </Typography>
      <FileUpload type={type} onFileUpload={handleFileUpload} file={file} />
      <Typography
        color='#999999'
        lineHeight={'15px'}
        fontSize={'10px'}
        fontWeight={'400'}
      >
        {textHelper}
      </Typography>
    </Box>
  )
}
